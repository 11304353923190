/***
 * Axid is short for Axor ID.
 * It is a string of form 'X.Y', where X and Y are hex-represented numbers,
 * which omit '0x' and leading zeroes, like '4f11-ff'. Lower case preferred.
 *
 * X translated to amount of seconds since epoch and Y is machine ID.
 * Both *expected* to be 32 bits or under, to have ability to be converted to 64 bits number,
 * or to have padding to make them sortable. However, that is not yet a strict requirement.
 *
 * Each machine ID can have only one Axid created per second due to constraint of uniqueness.
 * Clients are supposed to either wait for next second, or to request more machine IDs.
 * Machine IDs are given by server or any other authority,
 * which can guarantee their uniqueness at given time.
 *
 * Layout for machine IDs:
 * 0x00000000 - Reserved;
 * 0x00000001 - "The server" machine ID, which can create user's Axid;
 * 0x00000002 - For general errors, temporary messages and unknown senders;
 * 0x00000003 - For client-only system messages;
 * 0x00000004 - For mod/acc fields. Hidden user;
 * 0x00000005-0x0000007f - Reserved;
 * 0x00000080-0x000000ff - Special users;
 * 0x00000100-0xefffffff - Users, preferably distributed randomly, but also shorter IDs;
 * 0xf0000000-0xffffffff - Reserved.
 */
export default class Axid {
  public static SERVER_USER = '0-1';

  private static epoch: number = (2020-1970)*365*24*3600;

  private machineId: number;
  private lastTime: number;

  constructor(machineId: number) {
    this.machineId = machineId;
    this.lastTime = 0;
  }

  private tickNextSecond = async () => {
    while (Math.floor(Date.now() / 1000.0) - Math.floor(this.lastTime / 1000.0) < 1) {
      const toSleep = 1000 - (Date.now() % 1000);
      await sleep(toSleep);
    }

    this.lastTime = Date.now();
  }

  public generate = async () => {
    await this.tickNextSecond();
    return Axid.gen(this.machineId, this.lastTime);
  }

  private static gen = (machineId: number, timestamp: number) => {
    const hexSecond = (Math.floor(timestamp / 1000.0) - Axid.epoch).toString(16);
    const hexMachineId = machineId.toString(16);

    return hexSecond + '-' + hexMachineId;
  }

  public static asDate = (axid: string) => {
    return new Date(this.asSeconds(axid) * 1000);
  }

  public static asSeconds = (axid: string) => {
    const [ hexTimestamp ] = axid.split('-', 1);
    return parseInt(hexTimestamp, 16) + this.epoch;
  }

  public static axidSort = (axidArr: string[]) => {
    return [...axidArr]
      .map(h => h.split('-', 1)[0])
      .sort((a, b) =>
        a.length < b.length ? -1 : (a.length > b.length ? 1 : (a < b ? -1 : (a > b ? 1 : 0)))
      );
  }

  public static timestampDigitOrder = (hexDigit: number, precision: number = 2) => {
    const sec = Math.pow(16, hexDigit);
    if (sec < 60) {
      return sec.toFixed(precision) + ' seconds';
    } else if (sec < 3600) {
      return (sec/60.0).toFixed(precision) + ' minutes';
    } else if (sec < 3600 * 24) {
      return (sec/3600.0).toFixed(precision) + ' hours';
    } else if (sec < 3600 * 24 * 365) {
      return (sec/(3600.0 * 24.0)).toFixed(precision) + ' days';
    } else {
      return (sec/(3600.0 * 24.0 * 365)).toFixed(precision) + ' years';
    }
  }
}


const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));

import React, { useContext, useEffect, useState } from 'react';
import { getUserInfo } from '../state/user-info';
import { UserStoreContext } from '../state/user-store';
import { decryptMessage, encryptMessage } from '../encryption/messages';
import { observer } from 'mobx-react';
import { fetchPublicKey } from './api';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { AuthorAvatar } from './author-avatar';
import { isCallMessage, isPingMessage, MessagesStack } from './messages';
import { InputCell } from '../common/cell-container';
import { ILoadingStatus, IMessage, IMessageExid, TAxid } from '../types/message.interface';
import { API_URL, WS_URL } from '../common/api-config';
import { ChatStoreContext, IAttachment } from '../state/chat-store';
import { css } from '@emotion/react';
import { FileAttachment } from '../common/file-container';
import Axid from '../common/utils/axid';

interface IWebsocketMessage {
  op: number;
}

interface IMessageFromWebsocket extends IWebsocketMessage {
  content: string;
  author: string;
  exid?: TAxid;
}

interface IRawMessage {
  content: string;
}

interface IRawMessageTText {
  op: 1;
  axid: TAxid;
  author: TAxid;
  data: string;
  attachments?: IAttachment[];
  loadingStatus?: ILoadingStatus;
  replyTo?: TAxid;
}

interface IRawMessageTOnline {
  op: 2;
  data: boolean;
}

interface IRawMessageTTyping {
  op: 3;
  data: boolean;
}

interface IRawMessageTStream {
  op: 4;
  author: TAxid;
  totalParts: number;
  jsonPart: string;
  lastPart: boolean;
}

interface IRawMessageTAccepted {
  op: 5;
  exid: TAxid;
}

interface IRawMessageTDelivered {
  op: 6;
  exid: TAxid;
}

type IRawMessageContent = IRawMessageTText|IRawMessageTOnline|IRawMessageTTyping|IRawMessageTStream|IRawMessageTAccepted|IRawMessageTDelivered;

const ChatPageContainer = styled('div')`
  display: flex;
  height: 100vh;
  width: 100%;
`

const ChatChannelsContainer = styled('div')`
  display: flex;
  width: 13em;
  flex-direction: column;
  background: #202027;

  @media screen and (max-width: 600px) {
    display: none;
  }
`

const ChatChannelsListContainer = styled('div')`
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  min-height: 90vh;
  padding: 8%;
  justify-content: space-between;
`

const ChatPersonalArea = styled('div')`
  flex: 1;
  background: #171720;
  display: flex;
  align-items: center;
  padding-left: 25px;
`

const ChatMessagesContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const ChatInputContainer = styled('div')`
  display: flex;
  width: 100%;
`;

const ChatInput = styled(InputCell)`
  margin: 0 2.5% 0.5em 0;
  width: 95%;
  flex: none;
`;

const ChannelSelectorBlock = styled('div')<{ reactive?: boolean, colorful?: boolean }>`
  background: #393950;
  border-radius: 0.2em;
  padding: 0.4em;

  ${props => props.colorful && css`
    //background: linear-gradient(
    //        -45deg,
    //        #e73c7e, #e73c7e, #e73c7e,
    //        #ee7752, #e73c7e, #23a6d5, #23d5ab, #23a6d5, #e73c7e, #ee7752,
    //        #e73c7e, #e73c7e, #e73c7e);
    background: linear-gradient( -45deg, #23d5ab, #23d5ab, #23d5ab,
      #23a6d5,
      #3c44e7,
      #833ce7,
      #d13535,
      #e7a63c,
      #3ce741,
      #23d5ab, #23d5ab, #23d5ab);
    //background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 1200% 1200%;
    animation: gradient7 15s ease infinite;
    background-repeat: repeat;
  `}

  ${props => props.reactive && css`
    &:hover {
      filter: brightness(92%);
      transition: filter 0.1s;
    }
    &:active {
      filter: brightness(84%);
      transition: filter 0.1s;
    }
  `}
`;

const EmojisSelector = styled(ChannelSelectorBlock)`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;

  & > img {
    width: 100%;
    height: 100%;
    min-width: 0;
    padding: 0.2em;

    user-select: none;
    cursor: pointer;
  }
`

const TypingContainer = styled('div')`
  width: 2.5%;
  display: flex;
  line-height: 0;
  justify-content: center;
  align-items: center;
`;

const TypingImage = styled('img')<{ hidden: boolean }>`
  height: 0.4em;
  width: 2em;
  transform: translate(0, -50%) rotate(90deg);
  ${props => props.hidden ? 'display: none;' : ''}
`;

const IsOnlineText = styled('span')<{ isOnline: boolean }>`
  color: ${props => props.isOnline ? '#4bd375' : '#d9dd37'};
`

const ChatPage: React.FC<{ cid?: string }> = observer(({ cid: channelId = 'prime' }) => {
  const [target, setTarget] = useState<string>('');
  const [targetPublic, setTargetPublic] = useState<{ key: string, target: string }>({key: '', target: ''});
  const [websocket, setWebsocket] = useState<WebSocket|null>(null);

  const user = useContext(UserStoreContext);
  const chatCtx = useContext(ChatStoreContext);
  const { appendMessage } = chatCtx;
  const { editField } = user;

  const navigate = useNavigate();

  const targetAxid = target === 'alpha' ? '4a00000-1' : '4a70000-1';

  const getTargetPublicKey = async (target: string) => {
    if (!target) {
      throw new Error('No message target');
    }

    let pub: string|null = targetPublic.key;
    if (!targetPublic.key || targetPublic.target !== target) {
      pub = await fetchPublicKey(target);
      if (!pub) {
        sendSystemMessage('Chat is currently offline. Please, wait until there is available human.');
        throw new Error('Target not found');
      }

      setTargetPublic({ target, key: pub });
    }

    return pub;
  }

  const getCurrentTargetPublicKey = async () => await getTargetPublicKey(target);

  useEffect(() => {
    const onVisibilityChanged = async (isActive: boolean) => {
      if (!websocket) {
        return;
      }

      const pub = await getCurrentTargetPublicKey();

      const toSend: IRawMessageTOnline = { op: 2, data: isActive };
      const encryptedContent = await encryptMessage(JSON.stringify(toSend), pub);
      websocket.send(JSON.stringify({ content: encryptedContent, channel: Date.now() % 2 }));
    }

    onVisibilityChanged(chatCtx.documentActive);
  }, [chatCtx.documentActive]);

  const onLocalTypingChange = async (isTyping: boolean) => {
    if (websocket && isTyping) {
      websocket.send(JSON.stringify({ content: 1, channel: 1 }));
    }
  }

  // callback if someone sent me message, for some actions upon receive
  const onTextMessageReceived = (message: IMessage['content']) => {
    if (isPingMessage(message)) {
      window.axut?.sounds?.ping.play();
    } else if (isCallMessage(message)) {
      window.axut?.sounds?.call.play();
    }
  }

  const onMessage = async (message: IRawMessage) => {
    const messageContent: IRawMessageContent = JSON.parse(message.content);

    if (messageContent.op === 1) {
      appendMessage({
        content: messageContent.data, // TODO: rename
        ...messageContent,
      });

      onTextMessageReceived(messageContent.data);
    } else if (messageContent.op === 2) {
      chatCtx.onChatOnlineToggle(messageContent.data);
    } else if (messageContent.op === 3) {
      chatCtx.onChatReceivedTyping(messageContent.data);
    } else if (messageContent.op === 4) {
      chatCtx.onDataLoading(messageContent.jsonPart);
      chatCtx.filterOutLoadingMessages();
      if (messageContent.lastPart) {
        console.log('SAVING LAST')
        onMessage({
          content: chatCtx.dataLoading.data,
        });
        chatCtx.clearDataLoading();
      } else {
        const cont: IRawMessageTText = {
          op: 1,
          axid: await (new Axid(2)).generate(),
          author: messageContent.author,
          data: '',
          loadingStatus: {
            loading: true,
            partsDone: chatCtx.dataLoading.partsDone,
            partsTotal: messageContent.totalParts,
            rawData: chatCtx.dataLoading.data,
          }
        };

        onMessage({
          content: JSON.stringify(cont),
        })
      }
    } else if (messageContent.op === 6) {
      const { exid } = messageContent;
      chatCtx.onExidDelivered(exid);
    } else {
      throw new Error('UNKNOWN OP');
    }
  }

  const onWebsocketMessage = async (eventData: IWebsocketMessage, websocket: WebSocket, target: string) => {
    if (eventData.op === 1) {
      const encryptedMessage = eventData as IMessageFromWebsocket;

      if (encryptedMessage.content as any === 1) {
        chatCtx.onChatReceivedTyping(true);
      }

      const { exid } = encryptedMessage;
      if (exid) {
        const deliverResponse: IRawMessageTDelivered = {
          op: 6,
          exid,
        }

        const pub = await getTargetPublicKey(target);
        const encryptedContent = await encryptMessage(JSON.stringify(deliverResponse), pub);
        websocket.send(JSON.stringify({ content: encryptedContent, acquired: [exid] }));
      }

      onMessage({
        content: await decryptMessage(encryptedMessage.content, user.privateKey, user.passphrase) + '',
      })
    } else if (eventData.op === 3) {
      sendSystemMessage('Connection lost!')
      sendSystemMessage(':aah:')
    } else {
      throw new Error('Unknown OP');
    }
  }

  const sendSystemMessage = async (messageText: string) => {
    const sysAxid = await (new Axid(3)).generate()
    appendMessage({
      axid: sysAxid,
      content: messageText,
      author: Axid.SERVER_USER,
    })
  }

  const onWebsocketError = async (e: Event) => {
    console.warn("WEBSOCKET ERROR", e);
    sendSystemMessage('Apparently you got disconnected, trying to reconnect...');
    createWebsocket();
  }

  const createWebsocket = () => {
    if (!user.privateKey) {
      navigate('/login');
      return;
    }

    const target = user.username === 'alpha' ? 'beta' : 'alpha';
    setTarget(target);

    const ws = new WebSocket(`${WS_URL}/${user.username}`);
    ws.onmessage = function(event) {
      onWebsocketMessage(JSON.parse(event.data), ws, target);
    };
    ws.onclose = onWebsocketError;
    setWebsocket(ws);
  }

  useEffect(() => {
    createWebsocket();
  }, []);

  if (!websocket) {
    return <>No websocket</>;
  }

  const sendMessage = async () => {
    if (chatCtx.chatInput === '!download') {
      chatCtx.downloadMessages();
      chatCtx.onInputChange('');
      return;
    } else if (chatCtx.chatInput.startsWith('!set')) {
      try {
        const match = chatCtx.chatInput.match(/^!set\s+([^\s]+)\s+(.*)$/i);
        if (!match) {
          throw Error('Invalid !set match');
        }

        const [_, field, val] = match;
        editField(field, val);
        chatCtx.onInputChange('');
      } catch (err: any) {
        sendSystemMessage(`Failed to !set: ${err.message || '???'}`);
      }
      return;
    }

    const axid = await window.axid.generate();
    let exids: IMessageExid[] = []

    const pub = await getCurrentTargetPublicKey();

    // console.log('encrypting...', chatCtx.chatInput, { target, key: pub })

    const toSend: IRawMessageTText = {
      op: 1,
      axid,
      author: user.axid,
      data: chatCtx.chatInput,
      attachments: chatCtx.attachments,
      replyTo: chatCtx.currentReplyAxid,
    };
    const toSendJSON = JSON.stringify(toSend)
    const SEND_LIMIT = 4 * 1024 * 1024
    if (toSendJSON.length > SEND_LIMIT) {
      const totalChunks = Math.ceil(toSendJSON.length / SEND_LIMIT);
      console.log('starting to send chunked')
      for (let i = 0; i < toSendJSON.length; i += SEND_LIMIT) {
        const jsonPart = toSendJSON.slice(i, i + SEND_LIMIT);
        const streamMessage: IRawMessageTStream = {
          op: 4,
          author: user.axid,
          totalParts: totalChunks,
          jsonPart,
          lastPart: i + SEND_LIMIT >= toSendJSON.length,
        };
        const exid = await window.exid.generate();
        exids.push({ exid, status: 'sent' });
        const encryptedContent = await encryptMessage(JSON.stringify(streamMessage), pub);
        websocket.send(JSON.stringify({ content: encryptedContent, exid }));
      }
    } else {
      const exid = await window.exid.generate();
      exids.push({ exid, status: 'sent' });
      const encryptedContent = await encryptMessage(toSendJSON, pub);
      websocket.send(JSON.stringify({ content: encryptedContent, exid }));
    }

    appendMessage({
      axid,
      content: chatCtx.chatInput,
      author: user.axid,
      attachments: chatCtx.attachments,
      replyTo: chatCtx.currentReplyAxid,
      exids,
    });
    chatCtx.onInputChange('');
    chatCtx.clearMessageAttributes();
  }

  const userInfo = getUserInfo(user.axid);

  return <ChatPageContainer>
    <ChatChannelsContainer>
      <ChatChannelsListContainer>
        <div>
          <ChannelSelectorBlock
            colorful={true}
            style={{ padding: '0.3em 0.7em', width: '100%', marginBottom: '0.5em' }}
          >
            # Axor Prime
          </ChannelSelectorBlock>
          <ChannelSelectorBlock
            style={{
              marginBottom: '0.5em',
              fontSize: '0.8em',
              color: '#8c8ca1',
              cursor: 'pointer',
              userSelect: 'none',
            }}
            onClick={() => chatCtx.onDocumentActiveChange(!chatCtx.documentActive)}
            reactive={true}
          >
            I am currently <IsOnlineText
            isOnline={chatCtx.documentActive}>{chatCtx.documentActive ? 'here' : 'away'}</IsOnlineText>
          </ChannelSelectorBlock>
          <ChannelSelectorBlock style={{ fontSize: '0.8em', color: '#8c8ca1', marginBottom: '0.5em' }}>
            {getUserInfo(targetAxid).name} is <IsOnlineText
            isOnline={chatCtx.chatOnline}>{chatCtx.chatOnline ? 'maybe here' : 'surely away'}</IsOnlineText>
          </ChannelSelectorBlock>
          {chatCtx.attachments.length ? (
            <ChannelSelectorBlock style={{ fontSize: '0.8em' }}>
              {chatCtx.attachments[0].type.startsWith('image') ? (
                <img
                  src={chatCtx.attachments[0].data}
                  alt={'Preview'}
                  draggable={false}
                  style={{ maxHeight: '20vh', maxWidth: '100%', userSelect: 'none' }}
                />
              ) : (
                <FileAttachment data={chatCtx.attachments[0]}/>
              )}
            </ChannelSelectorBlock>
          ) : null}
        </div>
        <div>
          <EmojisSelector>
            {(window.axut?.emojis ?? []).map(emojiName => (
              <img
                src={`${API_URL}/media/emojis/${emojiName}`}
                alt={`:${emojiName}:`}
                // height={62.5}
                // width={62.5}
                // style={{ marginTop: '0.1em' }}
                draggable={false}
                onMouseDown={e => {
                  chatCtx.onEmojiClick(emojiName);
                  e.preventDefault();
                  e.stopPropagation();
                }}
                key={`:${emojiName}:`}
              />
            ))}
          </EmojisSelector>
        </div>
      </ChatChannelsListContainer>
      <ChatPersonalArea>
        <AuthorAvatar src={userInfo.icon} alt={userInfo.name} />
        <div>
          <div id='ws-id'>{userInfo.name}</div>
          <div style={{ fontSize: '0.6em' }}>
            Axor Chat v2.3{' '}
            <span style={{ color: '#808090' }}>(2023-07-14)</span>
          </div>
        </div>
      </ChatPersonalArea>
    </ChatChannelsContainer>
    <ChatMessagesContainer>
      <MessagesStack/>
      <ChatInputContainer>
        <TypingContainer>
          <TypingImage
            src={`${API_URL}/media/typing.gif`}
            alt={'typing'}
            hidden={!chatCtx.remoteTyping}
          />
        </TypingContainer>
        <ChatInputControl
          placeholder={`Message # Axor Prime`}
          onSendMessage={sendMessage}
          onLocalTypingChange={onLocalTypingChange}
        />
      </ChatInputContainer>
    </ChatMessagesContainer>
  </ChatPageContainer>;
})


interface IChatInputControl {
  placeholder: string;
  onSendMessage: () => void;
  onLocalTypingChange: (isTyping: boolean) => void;
}

const ChatInputControl: React.FC<IChatInputControl> = observer(props => {
  const [isActivelyTyping, setIsActivelyTyping] = useState<boolean>(false);
  const [localTypingEndTimeout, setLocalTypingEndTimeout] = useState<any>(null);

  const chatCtx = useContext(ChatStoreContext);

  useEffect(() => {
    if (!chatCtx.chatInput) {
      return;
    }

    if (!isActivelyTyping) {
      props.onLocalTypingChange(true);
      setIsActivelyTyping(true);
      setTimeout(() => setIsActivelyTyping(false), 600);

      if (localTypingEndTimeout) {
        clearTimeout(localTypingEndTimeout);
      }

      setLocalTypingEndTimeout(setTimeout(() => {
        props.onLocalTypingChange(false);
        setLocalTypingEndTimeout(null);
      }, 1500));
    }
  }, [chatCtx.chatInput]);

  const onImagePaste = (file: File) => {
    console.log('ONIMP', file.type);
    const reader = new FileReader();
    reader.onload = (event) => {
      // console.log(event.target?.result); // data url
      console.log('ONIMPL', file.type);
      chatCtx.onFilePasted(file.name, file.type, file.size, event.target?.result);
    };
    reader.readAsDataURL(file);
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      props.onSendMessage();
    }
  }

  return <ChatInput
    styleGroup={'primary'}
    value={chatCtx.chatInput}
    onChange={chatCtx.onInputChange}
    onKeyDown={onKeyDown}
    autofocus={true}
    placeholder={props.placeholder}
    onImagePaste={onImagePaste}
    showFileInput={true}
  />
});

export default ChatPage;

import styled from '@emotion/styled';

export const AuthorAvatar = styled('img')`
  margin-right: 0.8em;

  height: 2em;
  width: 2em;

  border-radius: 50%;
  box-shadow: 0 0 7px 5px rgba(0, 0, 0, 0.07);
`;

import styled from '@emotion/styled';
import { css, useTheme } from '@emotion/react';
import React, { useContext, useRef } from 'react';
import IconAttachment from '../chat/icons/attachment';
import file from '../chat/icons/file';
import { UserStoreContext } from '../state/user-store';
import { observer } from 'mobx-react';
import IconReply from '../chat/icons/reply';
import { ChatStoreContext } from '../state/chat-store';

const MAX_INPUT_LEN = 200;

interface ICellContainer {
  outlined: boolean;
  color: string;
  reactive: boolean;
  noFlex?: boolean;
}

export const CellContainer = styled.section<ICellContainer>`
  ${props => props.noFlex ? '' : css`flex: 18;`}
  padding: ${props => props.outlined ? `${0.6 - 0.16}em` : '0.6em'};
  min-height: 2.6em;

  box-shadow: 0 0.17em 0.17em rgba(0, 0, 0, 0.25);
  border-radius: 0.7em;
  
  display: flex;
  justify-content: space-between;
  
  ${props => props.outlined ? css`
    border: 0.16em solid ${props.color};
  ` : css`
    background: ${props.color};
  `}
  
  ${props => props.reactive && css`
    &:hover {
      filter: brightness(96%);
      transition: filter 0.1s;
    }
    &:active {
      filter: brightness(88%);
      transition: filter 0.1s;
    }
  `}
`;

interface ICellContainerStyled {
  styleGroup: 'primary' | 'secondary' | 'default';
  onClick?: (e: React.MouseEvent) => void;
  reactive?: boolean;
  noFlex?: boolean;
  style?: any;
  className?: any;
}

export const CellContainerStyled: React.FC<ICellContainerStyled> = observer(props => {
  const { theme } = useContext(UserStoreContext);

  return (
    <CellContainer
      outlined={false}
      color={props.styleGroup === 'primary' ? theme.primaryColor : theme.secondaryColor}
      onClick={props.onClick}
      reactive={!!props.reactive}
      noFlex={props.noFlex}
      style={props.style}
      className={props.className}
    >
      {props.children}
    </CellContainer>
  );
});

export const CellInput = styled.input`
  background: #0000;
  color: ${props => 'white'};
  font-size: 1em;

  outline: 0;
  border: 0;
  
  padding: 0;

  width: 100%;

  &::placeholder {
    color: ${props => 'white'};
    opacity: 80%;
  }
`;

interface IInputCell {
  placeholder?: string;
  value: string;
  onChange: (newValue: string) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  // onImagePaste: (file: File) => void;
  styleGroup: 'primary'|'secondary'|'default';
  leftOutsideText?: string;
  isPassword?: boolean;
  autofocus?: boolean;
  style?: any;
  className?: any;
  onImagePaste?: (file: File) => void;
  showFileInput?: boolean;
}

const FileInputButton = styled('button')`
  line-height: 0;
  stroke: white;
  fill: white;
`;

export const InputCell: React.FC<IInputCell> = observer(props => {
  const { showFileInput } = props;

  const fileInput = useRef<HTMLInputElement | null>(null);

  const { currentReplyAxid, setReplyTo } = useContext(ChatStoreContext);

  const onFileInputClick = () => {
    // `current` points to the mounted file input element
    if (fileInput?.current) {
      fileInput.current.click();
    }
  };

  const onFilePasted = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (fileInput.current && fileInput.current?.files && fileInput.current?.files[0]) {
      if (props.onImagePaste) {
        props.onImagePaste(fileInput.current.files[0]);
      }
      // console.log({ file:  })
    }
  }

  const onPaste = (event: React.ClipboardEvent) => {
    const items = (event.clipboardData).items;
    // console.log(JSON.stringify(items)); // might give you mime types
    for (const index in items) {
      const item = items[index];

      if (item.kind === 'file' && props.onImagePaste) {
        console.log('ONP', item.kind);

        const blob = item.getAsFile();
        if (blob) {
          console.log('ONPB', blob.size);
          event.preventDefault();

          props.onImagePaste(blob);
          // console.log(blob)
          // const reader = new FileReader();
          // reader.onload = function (event) {
          //   console.log(event.target?.result); // data url
          // };
          // reader.readAsDataURL(blob);
        }
      }
    }
  };

  const onReplyAxidClick = (event: React.MouseEvent) => {
    event.preventDefault();
    setReplyTo();
  }

  return <CellContainerStyled styleGroup={props.styleGroup} style={props.style} className={props.className}>
    <CellInput
      type={props.isPassword ? 'password' : 'text'}
      value={props.value}
      placeholder={props.placeholder}
      autoFocus={props.autofocus}
      onChange={event => props.onChange(event.target.value)}
      onKeyDown={e => props.onKeyDown(e)}
      maxLength={MAX_INPUT_LEN}
      autoComplete={'hidden'}
      spellCheck={false}
      onPaste={onPaste}
    />

    {currentReplyAxid !== '0-0' ? (
      <FileInputButton onMouseDown={onReplyAxidClick}>
        <IconReply />
      </FileInputButton>
    ) : null}
    {showFileInput ? (
      <>
        <FileInputButton onClick={onFileInputClick}>
          <IconAttachment />
        </FileInputButton>
        <input
          type='file'
          ref={fileInput}
          style={{ display: 'none' }}
          onChange={onFilePasted}
        />
      </>
    ) : null}
  </CellContainerStyled>
});

export const StackContainer = styled('div')`
  width: 80%;
  margin: 0 auto;

  @media screen and (max-width: 600px) {
    width: 90%;
  }
`;

export const StackContainerTiny = styled('div')`
  display: flex;
  flex-direction: column;

  align-items: center;

  & > * {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  & > section {
    width: 100%;
    flex: 0;
  }

  width: 20em;
  margin: 0 auto;

  @media screen and (max-width: 600px) {
    width: 90%;
  }

  height: 100%;
  justify-content: center;
`;

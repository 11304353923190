import { API_URL } from '../common/api-config';
import Axid from '../common/utils/axid';

export const fetchPublicKey = async (username: string): Promise<string | null> => {
  try {
    const resp = await fetch(`${API_URL}/public-key/${username}`);
    if (resp.status === 404) {
      return null;
    }
    return await resp.json();
  } catch (e) {
    console.log(e);
    return null;
  }
}

export const getAllEmojis = async (): Promise<string[]> => {
  try {
    const resp = await fetch(`${API_URL}/media/emojis`);
    return await resp.json();
  } catch (e) {
    console.log(e);
    return [];
  }
}

interface AxUtils {
  emojis?: string[];
  sounds?: {
    call: HTMLAudioElement,
    ping: HTMLAudioElement,
  },
}

declare global {
  interface Window {
    axut?: AxUtils;
    axid: Axid;
    exid: Axid;
  }
}

getAllEmojis().then(emojis => {
  window.axut = { ...window.axut, emojis };
});

import { IAttachment } from '../state/chat-store';
import Axid from '../common/utils/axid';
import { getUserInfo } from '../state/user-info';

export interface ILoadingStatus {
  loading: boolean;
  partsTotal: number;
  partsDone: number;
  rawData: string;
}

export type TAxid = string;

export interface IMessage {
  axid: TAxid;
  content: string;
  author: TAxid;
  attachments?: IAttachment[];
  loadingStatus?: ILoadingStatus;
  replyTo?: TAxid;
  exids?: IMessageExid[];
}

export interface IMessageExid {
  exid: TAxid;
  status: 'sent'|'accepted'|'delivered';
}

export class Message implements IMessage {
  public readonly axid: TAxid;
  public readonly content: string;
  public readonly author: TAxid;
  public readonly attachments?: IAttachment[];
  public readonly loadingStatus?: ILoadingStatus;
  public readonly replyTo?: TAxid;
  public readonly exids: IMessageExid[];

  constructor(message: IMessage) {
    this.axid = message.axid;
    this.content = message.content;
    this.author = message.author;
    this.attachments = message.attachments;
    this.loadingStatus = message.loadingStatus;
    this.replyTo = message.replyTo;
    this.exids = message.exids || [];
  }

  get timestamp() {
    return Axid.asDate(this.axid);
  }

  get authorInfo() {
    return getUserInfo(this.author)
  }

  get authorName() {
    return '';
  }

  get authorColor() {
    return '';
  }

  get authorIcon() {
    return '';
  }

  get isDelivered() {
    return this.exids.every(exid => exid.status === 'delivered');
  }
}

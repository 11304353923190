import { css } from '@emotion/react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
// @ts-ignore
import { Scrollbars } from 'react-custom-scrollbars';
import ReactMarkdown from 'react-markdown';

import { API_URL } from '../common/api-config';
import { CellContainerStyled } from '../common/cell-container';
import { FileAttachment, FileContainer } from '../common/file-container';
import Axid from '../common/utils/axid';
import { ChatStoreContext, IAttachment } from '../state/chat-store';
import { IUserInfo } from '../state/user-info';
import { UserStoreContext } from '../state/user-store';
import { ILoadingStatus, Message, TAxid } from '../types/message.interface';
import { AuthorAvatar } from './author-avatar';
import IconDoubleRight from './icons/double-right';
import IconReply from './icons/reply';

const dateObjToString = (date: Date): string => {
  // @ts-ignore
  return dayjs(date).calendar(null, {
    sameDay: 'HH:mm', // The same day ( Today at 2:30 AM )
    nextDay: '[Tomorrow at] HH:mm', // The next day ( Tomorrow at 2:30 AM )
    nextWeek: 'dddd [at] HH:mm', // The next week ( Sunday at 2:30 AM )
    lastDay: '[Yesterday at] HH:mm', // The day before ( Yesterday at 2:30 AM )
    lastWeek: '[Last] dddd [at] HH:mm', // Last week ( Last Monday at 2:30 AM )
    sameElse: 'YYYY-MM-DD' // Everything else ( 7/10/2011 )
  });
}

const MessageStackContainer = styled('div')`
  display: flex;
  flex-direction: column-reverse;
  flex: 1;

  max-height: 90vh;
  padding-bottom: 0.5em;
`;

const MessagesContainer = styled('div')`
  display: flex;
  flex-direction: column;

  section {
    width: 95%;
    margin: 0 auto 0.5em auto;
  }
`

const MessageContainer = styled('div')`
  display: flex;
  width: 100%
`;

const MessageRightContainer = styled('div')`
  display: flex;
  flex-direction: column;
  font-size: 0.77em;
  width: 100%;
`

const MessageTimestamp = styled('span')`
  color: #808090;
`;

const MessagesVerticalBlock = styled('div')`
  display: flex;
  flex-direction: column;

  & > * {
    margin-top: 0.2em;
  }
`;

const MessageTopContainer = styled('div')`
  margin-bottom: 0;
  display: flex;
`

const MessageImageFrame = styled('img')`
  max-height: 7em;
  cursor: pointer;
  user-select: none;
  max-width: 100%;
`

const ReplyContainer = styled('div')`
  font-size: 0.8em;
  color: #808090;

  cursor: pointer;
`;

interface IContentPlain { type: 'message', text: string, status: 'sent'|'delivered' }
interface IContentImage { type: 'image', data: any }
interface IContentFile { type: 'file', data: IAttachment }
interface IContentLoading { type: 'loading', data: ILoadingStatus }


interface IMessageContents {
  axid: TAxid;
  author: TAxid,

  timestamp: Date,
  replyTo?: TAxid;
  contents: Array<IContentPlain|IContentImage|IContentFile|IContentLoading>;
}

interface ContentBlock {
  authorInfo: IUserInfo;
  messages: IMessageContents[];
}

export const MessagesStack = observer(() => {
  const [contentBlocks, setContentBlocks] = useState<ContentBlock[]>([]);

  const scrollBar = useRef<any>();

  const chatCtx = useContext(ChatStoreContext);
  const { messages, markMessage } = chatCtx;

  const jumpTo = useCallback((channelAxid: string, messageAxid: string) => {
    const element = document.getElementById(messageAxid);
    if (!element) {
      console.error(`failed to scroll to ${messageAxid}`);
      return;
    }

    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    markMessage(messageAxid);
  }, []);

  const formContentBlocks = (fromMessages: Message[]): ContentBlock[] => {
    // console.log({ fromMessages });

    const blocks: ContentBlock[] = [];
    let currentBlock: ContentBlock|undefined = undefined;
    let lastMessage: Message|undefined = fromMessages.length ? fromMessages[0] : undefined;

    fromMessages.forEach(m => {
      const isSameBlock = lastMessage?.author === m.author
        && Axid.asSeconds(lastMessage?.axid) < 60 + Axid.asSeconds(m.axid);

      if (!isSameBlock && currentBlock) {
        blocks.push(currentBlock);
        currentBlock = undefined;
      }

      lastMessage = m;

      if (!currentBlock) {
        currentBlock = {
          authorInfo: m.authorInfo,
          messages: [],
        };
      }

      const contentMessage: IMessageContents = {
        axid: m.axid,
        author: m.author,
        timestamp: m.timestamp,
        contents: [{ type: 'message', text: m.content, status: m.isDelivered ? 'delivered' : 'sent' }],
        replyTo: m.replyTo,
      }

      if (m.attachments?.length) {
        const attachment = m.attachments[0];
        if (attachment.type.startsWith('image')) {
          contentMessage.contents.push({ type: 'image', data: attachment.data });
        } else {
          contentMessage.contents.push({ type: 'file', data: attachment });
        }
      }

      if (m.loadingStatus) {
        contentMessage.contents.push({ type: 'loading', data: m.loadingStatus });
      }

      currentBlock.messages.push(contentMessage);
    });

    if (currentBlock) {
      blocks.push(currentBlock);
    }

    return blocks;
  }

  useEffect(() => {
    setContentBlocks(prev => {
      const last = prev.pop();

      let firstIndex = 0;
      if (last) {
        const firstMessageInLastBlock = last.messages[0];
        firstIndex = messages.findIndex(m => firstMessageInLastBlock?.axid === m.axid);
      }

      return [
        ...prev,
        ...formContentBlocks(
          firstIndex === 0 ? messages : messages.slice(firstIndex)
        )
      ];
    })
  }, [messages]);

  useEffect(() => {
    // console.log("scrollbar change detected");
    if (scrollBar && scrollBar.current) scrollBar.current.scrollToBottom();
    // if (scrollBar && scrollBar.current) scrollBar.current.scrollIntoView({ block: 'end' });
  }, [scrollBar, contentBlocks]);

  return <MessageStackContainer>
    <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="100%" ref={scrollBar}>
      <MessagesContainer>
        <div style={{ minHeight: '1em' }}/>
        {contentBlocks.map(({ authorInfo, messages }) => (
          <CellContainerStyled styleGroup={'secondary'} noFlex={true} key={messages[0].axid}>
            <MessageContainer>
              <AuthorAvatar src={authorInfo.icon} alt={authorInfo.name} />
              <MessageRightContainer>
                <MessageTopContainer>
                  <span style={{ color: authorInfo.color, marginRight: '0.5em' }}>{authorInfo.name}</span>
                  <MessageTimestamp>{dateObjToString(messages[0].timestamp)}</MessageTimestamp>
                </MessageTopContainer>
                <MessagesVerticalBlock>
                  {messages.map(message =>
                    <div key={message.axid}>
                      {message.replyTo && message.replyTo !== '0-0' && (
                        <ReplyContainer onClick={() => message.replyTo && jumpTo('0-0', message.replyTo)}>
                          {'>'} {chatCtx.messages.find(m => m.axid === message.replyTo)?.content || '<unknown>'}
                        </ReplyContainer>
                      )}
                      <MessageContents key={message.axid} message={message} jumpTo={jumpTo}/>
                    </div>
                  )}
                </MessagesVerticalBlock>
              </MessageRightContainer>
            </MessageContainer>
          </CellContainerStyled>
        ))}
      </MessagesContainer>
    </Scrollbars>
  </MessageStackContainer>
});

const MarkableDiv = styled('div')<{ isMarked: boolean }>`
  position: relative;

  &:hover {
    filter: brightness(96%);
    //transition: filter 0.1s;
    background: #24243224;

    .message-actions {
      opacity: 1;
    }
  }

  ${props => props.isMarked && css`
    outline: #777777dd dashed 0.05em;
    border-radius: 0.2em;
    outline-offset: 0.1em;
  `}
`

const MessageActions = styled('div')<{ pos: 'left'|'right' }>`
  position: absolute;
  top: ${props => props.pos === 'right' ? css`-1em` : css`-0.1em`};
  background: #242432;
  //padding: 0.3em;
  border-radius: 0.3em;
  ${props => props.pos === 'right' ? css`right: 0.5em` : css`left: -2em`}
  opacity: 0;

  &, * {
    line-height: 0;
  }

  svg {
    fill: #aaa;
    stroke: #aaa;
  }
`

const MessageActionsButton = styled('button')`
  outline: none;
  border: none;

  padding: 0.3em;
  border-radius: 0.3em;

  &:hover svg {
    stroke: #eee;
    fill: #eee;
  }
`;

interface IComponentMessageContents {
  message: IMessageContents;
  jumpTo: (channelAxid: string, messageAxid: string) => void;
}

const MessageContents: React.FC<IComponentMessageContents> = observer((props) => {
  const { axid, contents } = props.message;

  const { markedMessage, currentReplyAxid, setReplyTo } = useContext(ChatStoreContext);
  const { settings } = useContext(UserStoreContext);
  const { messageActionsPosition } = settings;

  const onReplyActionClick = (event: React.MouseEvent) => {
    event.preventDefault();
    if (currentReplyAxid === axid) {
      setReplyTo();
    } else {
      setReplyTo(axid);
    }
  }

  return <MarkableDiv id={axid} isMarked={markedMessage === axid}>
    <MessageActions className='message-actions' pos={messageActionsPosition}>
      <MessageActionsButton onMouseDown={onReplyActionClick}>
        <IconReply />
      </MessageActionsButton>
    </MessageActions>

    {contents.map((content, j) => {
      const key = axid + '-' + contents.length + '-' + j;

      if (content.type === 'message') {
        return <MessageContent
          key={key}
          axid={axid}
          content={content.text}
          jumpTo={props.jumpTo}
          isDelivered={content.status === 'delivered'}
        />
      } else if (content.type === 'image') {
        return <a
          key={key}
          href={content.data}
          target={'_blank'}
          rel='noreferrer noopener'
        >
          <MessageImageFrame src={content.data} draggable={false}/>
        </a>
      } else if (content.type === 'file') {
        return <div key={key}>
          <a href={content.data.data} download={content.data.filename}>
            <FileAttachment data={content.data}/>
          </a>
        </div>
      } else {
        return <FileContainer key={key}>
          <img src={`${API_URL}/media/loading.gif`} alt='loading'/>
          <div>
            Loading message...
            <br/>
            Processed: {content.data.partsDone}/{content.data.partsTotal}
          </div>
        </FileContainer>
      }
    })}
  </MarkableDiv>
});

const EMOJI_REGEX = /^:(.*):$/;
const INNER_LINK_REGEX = /^\[([a-fA-F\d]+-[a-fA-F\d]+)\/([a-fA-F\d]+-[a-fA-F\d]+)\]/;

const ContentWrapper = styled('span')<{ isGrey?: boolean }>`
  overflow-wrap: anywhere;

  p {
    margin: 0;
  }

  em, strong {
    color: white;
    text-shadow: 0 0 0.04em #fff;
  }

  code {
    width: auto;
    height: auto;
    padding: 0.1em 0.4em;
    border-radius: 0.3em;
    font-size: 85%;
    font-family: Consolas,Andale Mono WT,Andale Mono,Lucida Console,Lucida Sans Typewriter,DejaVu Sans Mono,Bitstream Vera Sans Mono,Liberation Mono,Nimbus Mono L,Monaco,Courier New,Courier,monospace;
    text-indent: 0;
    border: none;
    white-space: pre-wrap;
    background: #00000040;
  }

  ${props => props.isGrey ? 'color: #777' : ''}
`;

const LinkButton = styled('button')`
  display: flex;

  outline: none;

  color: #279891;
  padding: 0.5em;
  background: #242432;
  font-size: 1em;
  border-radius: 0.5em;

  & > * + * {
    margin-left: 0.5em;
  }

  &:hover {
    filter: brightness(92%);
    transition: filter 0.1s;
  }

  &:active {
    filter: brightness(84%);
    transition: filter 0.1s;
  }

  svg {
    stroke: #279891;
    fill: #279891;
    height: 1.2em;
    width: 1.2em;
  }
`;

const CommandHighlight = styled('span')`
  color: #c600e2;
`;

const EmojiBlock = styled('div')`
  display: flex;
  gap: 0.5em;
`

export const isPingMessage = (content: string) => {
  if (content === '!ping') {
    return true;
  }

  return false;
};

export const isCallMessage = (content: string) => {
  if (content === '!call') {
    return true;
  }

  return false;
};

export const MessageContent = React.memo(({ axid, content, jumpTo, isDelivered }: {
  axid: string,
  content: string,
  jumpTo: (channelAxid: string, messageAxid: string) => void,
  isDelivered: boolean,
}) => {
  console.log({ axid, isDelivered }); 
  const defaultTextShow = (text: string) => <ContentWrapper isGrey={!isDelivered}><ReactMarkdown allowedElements={['p', 'code', 'strong', 'em']} unwrapDisallowed={true}>{text}</ReactMarkdown></ContentWrapper>

  const words = content.trim().split(/\s+/);
  if (words && words.length) {
    const emojiMatches = words.map(word => word.match(EMOJI_REGEX));
    const allEmojis = window.axut?.emojis ?? [];
    if (emojiMatches.every(match => match && allEmojis.includes(match[1]))) {
      return <EmojiBlock>
        {emojiMatches.map((emojiMatch: any) => (
          <img
            src={`${API_URL}/media/emojis/${emojiMatch[1]}`}
            alt={`:${emojiMatch[1]}:`}
            height={62.5}
            width={62.5}
            style={{ marginTop: '0.1em' }}
          />
        ))}
      </EmojiBlock>
    }

    return defaultTextShow(content);
  }

  const innerLinkMatch = content.match(INNER_LINK_REGEX);
  if (innerLinkMatch) {
    const channelAxid = innerLinkMatch[1];
    const messageAxid = innerLinkMatch[2];
    return <LinkButton
      onClick={() => jumpTo(channelAxid, messageAxid)}
    >
      <IconDoubleRight/>
      <span>#{channelAxid}</span>
      <span>{messageAxid}</span>
    </LinkButton>
  }

  const isCommand = isPingMessage(content) || isCallMessage(content);
  if (isCommand) {
    return <CommandHighlight>{content}</CommandHighlight>
  }

  return defaultTextShow(content);
});

import { makeAutoObservable, runInAction } from 'mobx';
import { createContext } from 'react';
import { IMessage, IMessageExid, Message, TAxid } from '../types/message.interface';
import doDownload from '../utils/download';

const REMOTE_TYPING_PAUSED_INTERVAL = 2000; //ms

export interface IAttachment {
  filename: string;
  type: string;
  size: number;
  data: any;
}

interface ICachedExid extends IMessageExid {
  messageAxid: TAxid;
}

export interface IChatStore {
  channelAxid: TAxid;
  messages: Message[];

  chatInput: string;
  attachments: IAttachment[];
  documentActive: boolean;
  chatOnline: boolean;
  remoteTyping: boolean;
  // localTyping: boolean;
  dataLoading: { partsDone: number, data: string };
  cachedExids: ICachedExid[];
}

export const defaultChatStore: IChatStore = {
  channelAxid: '0-0',
  messages: [],

  chatInput: '',
  attachments: [],
  documentActive: false,
  chatOnline: false,
  remoteTyping: false,
  // localTyping: false,
  dataLoading: { partsDone: 0, data: '' },
  cachedExids: [],
};

export class ChatStore implements IChatStore {
  channelAxid: TAxid = defaultChatStore.channelAxid;

  /***
   * Current cache of messages. Always should be ordered from oldest to newest,
   */
  messages: Message[] = [];

  chatInput: string = defaultChatStore.chatInput;
  attachments: IChatStore['attachments'] = defaultChatStore.attachments;
  documentActive: boolean = defaultChatStore.documentActive;
  chatOnline: boolean = defaultChatStore.chatOnline;
  remoteTyping: boolean = defaultChatStore.remoteTyping;
  // localTyping: boolean = defaultChatStore.localTyping;
  dataLoading: IChatStore['dataLoading'] = defaultChatStore.dataLoading;
  cachedExids: ICachedExid[] = defaultChatStore.cachedExids;

  currentReplyAxid: TAxid = '0-0';

  markedMessage: TAxid = '0-0';

  private stopRemoveTypingTimeout: any = null;

  constructor() {
    makeAutoObservable(this);
    // this.documentActivityMonitorStart();
  }

  setReplyTo = (messageAxid?: TAxid) => {
    this.currentReplyAxid = messageAxid || '0-0'
    this.markedMessage = messageAxid || '0-0'
  }

  appendMessage = (message: IMessage) => {
    this.messages = [...this.messages, new Message(message)];
    if (message.exids && message.exids.length) {
      const newExids = message.exids
        .filter(exid => exid.status === 'sent')
        .map(exid => ({ ...exid, messageAxid: message.axid }));

      this.cachedExids = [...this.cachedExids, ...newExids];
    }
  }

  onExidDelivered = (exid: TAxid) => {
    const exidObj = this.cachedExids.find(ex => ex.exid === exid);
    if (!exidObj) return;
    const { messageAxid } = exidObj;
    const message = this.messages.find(message => message.axid === messageAxid);
    if (!message) return;
    const messageExid = message.exids.find(ex => ex.exid === exid)
    if (!messageExid) return;
    messageExid.status = 'delivered';

    // lazy hack to reload all messages, changing field isn't enough
    this.messages = [...this.messages];
    this.cachedExids = this.cachedExids.filter(ex => ex.exid !== exid);
  }

  filterOutLoadingMessages = () => {
    this.messages = this.messages.filter(m => !m.loadingStatus?.loading);
  }

  markMessage = (axid: TAxid) => {
    this.markedMessage = axid;
  }

  onDocumentActiveChange = (newState: boolean) => {
    this.documentActive = newState;
  }

  onInputChange = (newInput: string) => {
    this.chatInput = newInput;
  }

  onChatOnlineToggle = (newState: boolean) => {
    this.chatOnline = newState;
  }

  onChatReceivedTyping = (newState: boolean) => {
    this.remoteTyping = newState;
    if (this.stopRemoveTypingTimeout) {
      clearTimeout(this.stopRemoveTypingTimeout);
    }

    if (newState) {
      this.stopRemoveTypingTimeout = setTimeout(
        () => runInAction(() => {
          this.remoteTyping = false;
          this.stopRemoveTypingTimeout = null;
        }),
        REMOTE_TYPING_PAUSED_INTERVAL,
      );
    } else {
      this.stopRemoveTypingTimeout = null;
    }
  }

  onEmojiClick(emojiName: string) {
    const emojiNameArmored = `:${emojiName}:`;

    if (this.chatInput.length && !(this.chatInput.endsWith(' '))) {
      this.onInputChange(this.chatInput + ' ' + emojiNameArmored);
    } else {
      this.onInputChange(emojiNameArmored);
    }
  }

  onFilePasted = (filename: string, mime: string, size: number, fileData: any) => {
    console.log(filename);
    this.attachments = [{
      filename,
      type: mime,
      size,
      data: fileData,
    }]
  }

  clearMessageAttributes = () => {
    this.attachments = [];
    this.setReplyTo();
  }

  onDataLoading = (toAppend: string) => {
    this.dataLoading = {
      partsDone: this.dataLoading.partsDone + 1,
      data: this.dataLoading.data + toAppend,
    };
  }

  clearDataLoading = () => {
    this.dataLoading = {
      partsDone: 0,
      data: '',
    };
  }

  downloadMessages = () => {
    const preparedMessages = this.messages.reduce<IMessage[]>((acc, message) => {
      const msg = { ...message };
      if (msg.replyTo === '0-0') {
        delete msg.replyTo;
      }

      if (!msg.attachments || msg.attachments?.length === 0) {
        delete msg.attachments;
      }

      acc.push(msg);
      return acc;
    }, []);

    const date = new Date().toISOString().split('T')[0];
    const filename = `axc-${date}.json`;
    doDownload(filename, preparedMessages);
  }
}

export const ChatStoreContext = createContext<ChatStore>(new ChatStore());

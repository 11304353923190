import React, { useContext, useState } from 'react';
import {
  createMessage,
  decrypt,
  decryptKey,
  encrypt,
  generateKey,
  readKey,
  readMessage,
  readPrivateKey,
} from 'openpgp';
import { UserStoreContext } from '../state/user-store';
import { useNavigate } from 'react-router-dom';
import { sendLogin } from './api';
import { observer } from 'mobx-react';
import {
  CellContainerStyled,
  InputCell,
  StackContainerTiny,
} from '../common/cell-container';
import Axid from '../common/utils/axid';

interface IKeyPair {
  privateKey: string;
  publicKey: string;
}

declare global {
  interface Window {
    axcr?: any;
  }
}

window.axcr = {};

const generateKeys = async (name: string, passphrase: string): Promise<IKeyPair> => {
  const { privateKey, publicKey, revocationCertificate } = await generateKey({

    type: 'ecc', // Type of the key, defaults to ECC
    curve: 'curve25519', // ECC curve name, defaults to curve25519
    userIDs: [{ name }], // you can pass multiple user IDs
    passphrase, // protects the private key
    format: 'armored' // output key format, defaults to 'armored' (other options: 'binary' or 'object')
  });

  return { privateKey, publicKey };
};

window.axcr.generateKeys = generateKeys;

const encryptMessage = async (message: string, publicKeyArmored: string) => {
  const publicKey = await readKey({ armoredKey: publicKeyArmored });

  const encrypted = await encrypt({
    message: await createMessage({ text: message }), // input as Message object
    encryptionKeys: publicKey,
    // signingKeys: privateKey // optional
  });
  console.log(encrypted); // '-----BEGIN PGP MESSAGE ... END PGP MESSAGE-----'

  return await readMessage({
    armoredMessage: encrypted // parse armored message
  });
}

window.axcr.encryptMessage = encryptMessage;

const decryptMessage = async (message: any, privateKeyArm: string, passphrase: string) => {
  const privateKey = await decryptKey({
    privateKey: await readPrivateKey({ armoredKey: privateKeyArm }),
    passphrase
  });

  const { data: decrypted, signatures } = await decrypt({
    message,
    // verificationKeys: publicKey, // optional
    decryptionKeys: privateKey
  });
  console.log(decrypted);

  // check signature validity (signed messages only)
  // try {
  //   await signatures[0].verified; // throws on invalid signature
  //   console.log('Signature is valid');
  // } catch (e) {
  //   throw new Error('Signature could not be verified: ' + e.message);
  // }
}

window.axcr.decryptMessage = decryptMessage;

const cycleAround = async (publicKey: string, privateKey: string, passphrase: string) => {
  const message = await encryptMessage('Glory to cryptography!', publicKey);
  return await decryptMessage(message, privateKey, passphrase);
}

window.axcr.cycleAround = cycleAround;

const LoginPage: React.FC = observer(() => {
  const [ username, setUsername ] = useState<string>('');
  const [ password, setPassword ] = useState<string>('');

  const user = useContext(UserStoreContext);
  const navigate = useNavigate();

  const [keyPair, setKeyPair] = useState<IKeyPair>({ privateKey: '', publicKey: '' });
  const [keyPair2, setKeyPair2] = useState<IKeyPair>({ privateKey: '', publicKey: '' });

  const regenerateKeys = async () => {
    setKeyPair(await generateKeys('a1', 'super pass phrase'));
    setKeyPair2(await generateKeys('a2', 'super pass phrase 2'));
  }

  const cycleAround = async () => {
    const message = await encryptMessage('Glory to cryptography!', keyPair.publicKey);
    await decryptMessage(message, keyPair.privateKey, 'super pass phrase');
    await decryptMessage(message, keyPair2.privateKey, 'super pass phrase 2');
  }

  const onLogin = async () => {
    const keys = await generateKeys(username, username);
    try {
      const resp = await sendLogin(username, `${username}_token`, keys.publicKey);
      if (!resp.machineId) {
        throw new Error('Empty machine ID');
      }

      window.axid = new Axid(resp.machineId);
      window.exid = new Axid(resp.eventMachineId);

      await user.onLogin(username, resp.userAxid, keys.publicKey, keys.privateKey);
      console.log({ userL: user });
      navigate('/chat');
    } catch (e) {
      console.error(e);
      return;
    }
  }

  const onPasswordKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onLogin();
    }
  }

  return <StackContainerTiny>
    <h1 style={{ marginTop: 0 }}>Axor Chat App</h1>
    <InputCell styleGroup={'secondary'} value={username} onChange={setUsername} onKeyDown={() => 0} autofocus={true} placeholder={'Username'}/>
    <InputCell styleGroup={'secondary'} value={password} onChange={setPassword} onKeyDown={onPasswordKeyDown} isPassword={true} placeholder={'Password'}/>
    <CellContainerStyled styleGroup={'primary'} reactive={true} onClick={onLogin}>Log in</CellContainerStyled>
    {/*/!*<input value={username} onChange={e => setUsername(e.currentTarget.value)}/>*!/*/}
    {/*/!*<input value={password} onChange={e => setPassword(e.currentTarget.value)} type={'password'}/>*!/*/}
    {/*<button onClick={onLogin}>login</button>*/}
  </StackContainerTiny>;
})

export default LoginPage;

import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LandingPage from './landing';
import LoginPage from '../login-page';
import ChatPage from '../chat';
import { UserStore, UserStoreContext } from '../state/user-store';
import { observer } from 'mobx-react';

const App = observer(() => {
  return (
    <UserStoreContext.Provider value={new UserStore()}>
      <BrowserRouter>
        <Routes>
          <Route path={'/login'} element={<LoginPage/>} key='login'/>
          <Route path={'/chat'} element={<ChatPage/>} key='chat'/>
          <Route path={'/chat/az'} element={<ChatPage/>} key='chat-az'/>
          <Route path={'/chat/aa'} element={<ChatPage/>} key='chat-aa'/>
          <Route path={'*'} element={<LoginPage/>} key='login'/>
        </Routes>
      </BrowserRouter>
    </UserStoreContext.Provider>
  );
})

window.axut = {
  ...window.axut,
  sounds: {
    call: new Audio('mixkit-urgent-simple-tone-loop-2976.wav'),
    ping: new Audio('mixkit-positive-notification-951.wav'),
  },
}

export default App;

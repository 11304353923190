import { API_URL } from '../common/api-config';
import Axid from '../common/utils/axid';
import { TAxid } from '../types/message.interface';

export interface IUserInfo {
  name: string;
  color: string;
  icon: string;
}

export const getUserInfo = (axid: TAxid): IUserInfo => {
  const icon = `${API_URL}/media/pfps/${axid}_L.webp`;

  if (axid === Axid.SERVER_USER) {
    return {
      name: 'SYSTEM',
      color: '#dd3737',
      icon,
    }
  }

  if (axid === '4a00000-1') {
    return {
      name: 'Flor',
      color: 'gold',
      icon,
    }
  }

  if (axid === '4a70000-1') {
    return {
      name: 'yu-tsu-ken',
      color: '#afeec7',
      icon,
    }
  }

  return {
    name: axid,
    color: 'white',
    icon: `${API_URL}/media/axor-cat.png`,
  }
}

import { createMessage, decrypt, decryptKey, encrypt, readKey, readMessage, readPrivateKey } from 'openpgp';

export const encryptMessage = async (message: string, publicKeyArmored: string) => {
  // console.log({ message, publicKeyArmored });
  const publicKey = await readKey({ armoredKey: publicKeyArmored });

  const encrypted = await encrypt({
    message: await createMessage({ text: message }), // input as Message object
    encryptionKeys: publicKey,
    // signingKeys: privateKey // optional
  });
  // console.log(encrypted); // '-----BEGIN PGP MESSAGE ... END PGP MESSAGE-----'

  return encrypted;
}

export const decryptMessage = async (message: any, privateKeyArm: string, passphrase: string) => {
  const privateKey = await decryptKey({
    privateKey: await readPrivateKey({ armoredKey: privateKeyArm }),
    passphrase
  });

  // console.log({ toDec: message });

  const rawMessage = await readMessage({
    armoredMessage: message // parse armored message
  });

  const { data: decrypted, signatures } = await decrypt({
    message: rawMessage,
    // verificationKeys: publicKey, // optional
    decryptionKeys: privateKey
  });

  return decrypted;

  // check signature validity (signed messages only)
  // try {
  //   await signatures[0].verified; // throws on invalid signature
  //   console.log('Signature is valid');
  // } catch (e) {
  //   throw new Error('Signature could not be verified: ' + e.message);
  // }
}
import { makeAutoObservable } from 'mobx';
import { createContext } from 'react';
import ITheme from '../types/theme.interface';
import IUserStore from './user-store.interface';
import { TAxid } from '../types/message.interface';
import IUserSettings from '../types/user-settings.interface';

export const defaultUserStore: IUserStore = {
  theme: {
    primaryColor: '#279891',
    secondaryColor: '#393950',
  },
  token: '',
  username: 'John',
  axid: '1-1',
  settings: {
    messageActionsPosition: 'right',
  },

  publicKey: '',
  privateKey: '',
  passphrase: '',
};

export class UserStore implements IUserStore {
  theme: ITheme = defaultUserStore.theme;
  token: string = defaultUserStore.token;
  username: string = defaultUserStore.username;
  axid: TAxid = defaultUserStore.axid;

  publicKey: string = defaultUserStore.publicKey;
  privateKey: string = defaultUserStore.privateKey;
  passphrase: string = defaultUserStore.passphrase;

  settings: IUserSettings = defaultUserStore.settings;

  constructor() {
    makeAutoObservable(this);
  }

  onLogin(username: IUserStore['username'], axid: IUserStore['axid'], publicKey: IUserStore['publicKey'], privateKey: IUserStore['privateKey']) {
    this.username = username;
    this.axid = axid;
    this.publicKey = publicKey;
    this.privateKey = privateKey;
    this.passphrase = username;

    if (this.username === 'alpha') {
      this.settings.messageActionsPosition = 'left';
    }
  }

  private _editField = (root: 'theme'|'settings', field: string, value: string) => {
    if (field.includes('.')) {
      throw new Error('No nested fields');
    }

    const currentRaw = JSON.parse(JSON.stringify(root === 'theme' ? this.theme : this.settings));
    console.log({ currentRaw, field });
    if (Object.hasOwn(currentRaw, field)) {
      if (root === 'theme') {
        this.theme[field as keyof ITheme] = JSON.parse(value);
      } else if (root === 'settings') {
        this.settings[field as keyof IUserSettings] = JSON.parse(value);
      }
    } else {
      throw new Error('Field not found');
    }
  }

  editField = (field: string, value: string) => {
    if (field.startsWith('theme.')) {
      const fieldCut = field.slice(6);
      this._editField('theme', fieldCut, value);
    } else if (field.startsWith('settings.')) {
      const fieldCut = field.slice(9);
      this._editField('settings', fieldCut, value);
    } else {
      throw new Error('Area not found');
    }
  }
}

export const UserStoreContext = createContext<UserStore>(new UserStore());

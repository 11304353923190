import { API_URL } from '../common/api-config';
import { TAxid } from '../types/message.interface';


interface ILoginResponse {
  machineId: number;
  eventMachineId: number;
  userAxid: TAxid;
}

export const sendLogin = async (username: string, token: string, publicKey: string): Promise<ILoginResponse> => {
  const resp = await fetch(`${API_URL}/login`, {
    method: 'POST',
    body: JSON.stringify({
      username,
      token,
      public_key: publicKey,
    }),
    headers: {
      "Content-type": "application/json"
    },
  });

  if (resp.ok) {
    return await resp.json();
  } else {
    throw new Error('Access denied');
  }
}

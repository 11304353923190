import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from '../app/App';
import reportWebVitals from './reportWebVitals';
import {observer} from 'mobx-react-lite'
import {createContext, useContext} from "react"
import { makeAutoObservable } from 'mobx';
import dayjs from 'dayjs';


// class Timer {
//   secondsPassed = 0
//
//   constructor() {
//     makeAutoObservable(this)
//   }
//
//   increaseTimer() {
//     this.secondsPassed += 1
//   }
// }
//
//
// const TimerContext = createContext<Timer>(new Timer())
//
// const TimerView = observer(() => {
//   // Grab the timer from the context.
//   const timer = useContext(TimerContext) // See the Timer definition above.
//   return (
//     <span>Seconds passed: {timer.secondsPassed}</span>
//   )
// })
//
// const myTimer = new Timer()
//
// ReactDOM.render(
//   <TimerContext.Provider value={myTimer}>
//     <TimerView />
//   </TimerContext.Provider>,
//   document.getElementById('root'),
// )
//
// setInterval(() => {
//   myTimer.increaseTimer()
// }, 1000)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


const calendar = require('dayjs/plugin/calendar')
dayjs.extend(calendar)

import styled from '@emotion/styled';
import IconFile from '../chat/icons/file';
import humanFileSize from './utils/file-size';
import React from 'react';
import { IAttachment } from '../state/chat-store';


export const FileContainer = styled('div')`
  display: flex;
  background: #202027;
  padding: 0.5em;
  margin: 0.2em 0;
  border-radius: 0.4em;
  
  color: rgb(140, 140, 161);
  
  svg {
    margin-right: 0.3em;
    stroke: rgb(140, 140, 161);
    fill: rgb(140, 140, 161);
    min-width: min-content;
  }
`;


interface IFileAttachment {
  data: IAttachment;
}


export const FileAttachment = (props: IFileAttachment) => {
  return <FileContainer>
    <IconFile/>
    <div>
      {props.data.filename}
      <br/>
      Size: {humanFileSize(props.data.size)}
    </div>
  </FileContainer>
}
